import React, { createContext, useState, useCallback, useEffect } from 'react';
import DBHandler from '../DBHandler';

const PsyListContext = createContext();

export const PsyListProvider = ({ children }) => {
    const [therapist, setTherapist] = useState([]);
    const [peer, setPeer] = useState([]);
    const [loadingTherapist, setLoadingTherapist] = useState(false);
    const [loadingPeer, setLoadingPeer] = useState(false);
    const [therapistLastDoc, setTherapistLastDoc] = useState(null);
    const [peerLastDoc, setPeerLastDoc] = useState(null);

    const dbHandler = new DBHandler();

    const mergeTherapistData = (prev, newTherapists) => {
        const existingIds = new Set(prev.map((psy) => psy.id));
        const mergedData = [...prev, ...newTherapists.filter(psy => !existingIds.has(psy.id))];
        return mergedData;
    };

    // Fetch psychologists with lazy loading
    const fetchPsyListLazy = useCallback(async (isPsy, limit = 25, lastDoc = null) => {
        if (isPsy) {
            setLoadingTherapist(true);
            const { psychologists, lastDocument } = await dbHandler.fetchPsychologistListLazy(isPsy, limit, lastDoc);
            setTherapist((prev) => mergeTherapistData(prev, psychologists));
            setTherapistLastDoc(lastDocument);
            setLoadingTherapist(false);
        } else {
            setLoadingPeer(true);
            const { psychologists, lastDocument } = await dbHandler.fetchPsychologistListLazy(isPsy, limit, lastDoc);
            setPeer((prev) => mergeTherapistData(prev, psychologists));
            setPeerLastDoc(lastDocument);
            setLoadingPeer(false);
        }
    }, []);

    // Real-time listener for status updates for both lists
    const subscribeToStatusUpdates = useCallback(() => {
        const dbHandler = new DBHandler();
        return dbHandler.onPsychologistStatusChange((updatedStatus) => {
            if (updatedStatus.isPsy) {
                setTherapist((prevList) =>
                    prevList.map((psy) =>
                        psy.id === updatedStatus.id ? { ...psy, status: updatedStatus.status } : psy
                    )
                );
            } else {
                setPeer((prevList) =>
                    prevList.map((psy) =>
                        psy.id === updatedStatus.id ? { ...psy, status: updatedStatus.status } : psy
                    )
                );
            }
        });
    }, []);

    useEffect(() => {
        fetchPsyListLazy(true);
        fetchPsyListLazy(false);

        const unsubscribe = subscribeToStatusUpdates();

        return () => unsubscribe();
    }, [fetchPsyListLazy, subscribeToStatusUpdates]);

    // Load more data when the user scrolls near the bottom
    const loadMoreTherapists = () => {
        if (!loadingTherapist && therapistLastDoc) {
            fetchPsyListLazy(true, 25, therapistLastDoc);
        }
    };

    const loadMorePeers = () => {
        if (!loadingPeer && peerLastDoc) {
            fetchPsyListLazy(false, 25, peerLastDoc);
        }
    };

    return (
        <PsyListContext.Provider value={{
            therapist,
            peer,
            loadingTherapist,
            loadingPeer,
            loadMoreTherapists,
            loadMorePeers,
        }}>
            {children}
        </PsyListContext.Provider>
    );
};

export const usePsyList = () => React.useContext(PsyListContext);
