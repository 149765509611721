import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/navbar';
import { Rating } from '@mui/material';
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './ConsultationDetails.css';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { AuthContext } from "../../Context/AuthContext";

const ConsultationDetails = () => {

  const location = useLocation();
  const callData = location.state?.callData || {};
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleBookAgain = (psychologist) => {
    const route = psychologist.professional ? `/user/therapist/${psychologist.id}` : `/user/peer/${psychologist.id}`;
    navigate(route, { state: { psychologist } });
  }

  const getDateTime = () => {
    const convertTimestampToDate = (timestamp) => {
      return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    };

    if (callData.callType === 'Live Call') {
      return convertTimestampToDate(callData.timestamp);
    } else {
      return callData.dateTimeSlot instanceof Date ? callData.dateTimeSlot : convertTimestampToDate(callData.dateTimeSlot);
    }
  };


  const dateTimeSlot = getDateTime();

  const dateOptions = { day: 'numeric', month: 'short', year: 'numeric', timeZone: 'Asia/Kolkata' };
  const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'Asia/Kolkata' };

  const dateFormatter = new Intl.DateTimeFormat('en-IN', dateOptions);
  const timeFormatter = new Intl.DateTimeFormat('en-IN', timeOptions);

  const formattedDate = dateFormatter.format(dateTimeSlot);
  const formattedTime = timeFormatter.format(dateTimeSlot);

  return (
    <div className='user_home'>
      <Sidebar userType={'user'} />
      <div className='homeContainer consulthomecont'>
        <Navbar userData={currentUser} userType={'user'} />
        <div className="bookingconf_cont consulcont">
          <div className="booking_user_card">
            <div className="bookimg_cont">
              <img src={callData.psyData.imagePath} alt={callData.psyData.name} className="user-image" />
            </div>
            <div className="user_details">
              <h2 className="therapist-name">Dr. {callData.psyData.name}</h2>
              <h3 className="therapist-subheading">{callData.psyData.professional ? "Therapist" : "Peer"}</h3>
              <p className="therapist-areas">{callData.psyData.areas.join(', ')}</p>
              <p className="therapist-languages">{callData.psyData.languages.join(', ')}</p>
              <div className="therapist-rating">
                <Rating
                  name="therapist-rating"
                  value={callData.psyData.rating}
                  precision={0.5}
                  readOnly
                  size="large"
                  emptyIcon={<FaStar style={{ color: '#dcdcdc' }} />}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Bill Details */}
        <div className='bill_container consultationbill consulthomecont'>
          <h2 className="bill-heading">Bill Details</h2>
          <div className="bill-item">
            <span>Consultation Fee</span>
            <span className='span_bold'>₹ {callData.psyData.price}</span>
          </div>
          <div className="bill-item">
            <span>Service Fee</span>
            <span className='span_bold'>₹50.00</span>
          </div>
          <div className="bill-item bill_divider">
            <span>Tax</span>
            <span className='span_bold'>1.00%</span>
          </div>
          <div className="bill-item total">
            <span>Total Payable</span>
            <span>₹ {callData.amt}</span>
          </div>
          <div className="bill-item bill_divider">
            <span>Credit Discount</span>
            <span className='span_bold'>₹ {callData.discount}</span>
          </div>
          <div className="bill-item final">
            <span>Final Amount</span>
            <span>₹ {callData.amt}</span>
          </div>
        </div>

        {/* Client Details */}
        <div className='client_container consultclient'>
          <h2 className="client-heading">Client Details</h2>
          <div className="client-item">
            <span>User Phone Number</span>
            <span className='span_bold'>+91 {callData.userPhone}</span>
          </div>
          <div className="client-item">
            <span>User Name</span>
            <span className='span_bold'>{callData.userName}</span>
          </div>
        </div>
        <div className="order_info_container">
          <h2 className="order-heading">Order Information</h2>
          <div className="order-item">
            <span>Booking ID</span>
            <span className='order-detail'>{callData.bookingId}</span>
          </div>
          <div className="order-item">
            <span>Payment ID</span>
            <span className='order-detail'>{callData.paymentId}</span>
          </div>
          <div className="order-item">
            <span>{callData.callType === 'Booking' ? 'Appointment Date' : 'Call Date & Time'}</span>
            <span className='order-detail'>{`${formattedDate} @${formattedTime}`}</span>
          </div>
          <div className="order-rating">
            <Rating
              name="order-rating"
              value={0}
              readOnly
              emptyIcon={<FaStar style={{ color: '#dcdcdc' }} fontSize="inherit" />}
            />
            <button className="book-again-button" onClick={() => handleBookAgain(callData.psyData)}>
              <AutorenewIcon style={{ marginRight: '8px' }} />
              Book Again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultationDetails;
