import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleButton } from 'react-google-button';
import { signInWithPopup, OAuthProvider } from "firebase/auth";
import logoImage from '../../Assets/hapynobg.png';
import therapistRegLogo from '../../Assets/therapistReg.jpg';
import HomeNavbar from '../../Components/Navbar';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, InputAdornment, OutlinedInput, Grid } from '@mui/material';
import { auth, provider, appleProvider, requestPermission } from '../../Config/Config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DBHandler from '../../Utils/DBHandler';
import { usePsychologist } from '../../Utils/Psychologist/PsychologistContext';
import { AuthContext } from '../../Context/AuthContext';
import './style.css';

export default function TherapistRegister() {
    const navigate = useNavigate();
    const scrollRef = useRef();
    const dbHandler = new DBHandler();

    const { psychologist, modifyPsychologist } = usePsychologist();
    const { dispatch, setUserType } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
    const [isLoadingApple, setIsLoadingApple] = useState(false);

    const [contactError, setContactError] = useState('');
    const [fullName, setFullName] = useState("");
    const [emailAddress, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [countryCode, setCountryCode] = useState("91");
    const [password, setPassword] = useState("");
    const [selectedProfession, setSelectedProfession] = useState('');

    const handleContactChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setContactNumber(value);
            if (value.length < 7 || value.length > 15) {
                setContactError('Contact number must be between 7 and 15 digits.');
            } else {
                setContactError('');
            }
        } else {
            setContactError('Invalid Contact Number');
        }
    };

    const handleGoogleClick = async () => {
        try {
            setIsLoadingGoogle(true);
            const data = await signInWithPopup(auth, provider);
            const userAuth = data.user;

            // Step 1: Check if a custom UID exists for this Firebase UID
            let customUID = await dbHandler.getCustomUID(userAuth.uid);

            if (!customUID) {
                // Step 2: If no custom UID exists, the user is new. Generate a custom UID
                customUID = generateCustomUID('therapist');

                // Step 3: Store the custom UID and Firebase UID mapping
                await dbHandler.storeUIDReference(userAuth.uid, customUID);

                // Step 4: Create new psychologist data with the custom UID
                const psyData = {
                    id: customUID,
                    uid: userAuth.uid,
                    name: userAuth.displayName,
                    email: userAuth.email,
                    phone: userAuth.phoneNumber ? userAuth.phoneNumber : '',
                    countryCode: '91',
                };

                // Step 5: Modify the psychologist state
                await modifyPsychologist(psyData);

                // Step 6: Store psychologist data in Firestore under the custom UID
                await dbHandler.addPsychologist(psychologist);

            } else {
                // Step 7: Fetch existing psychologist data using the custom UID
                const psychologistData = await dbHandler.getPsychologist(customUID);
                await modifyPsychologist(psychologistData);
            }

            // Step 8: Check if the psychologist has completed their details
            const showIntroDetailPage = await dbHandler.doesPsychologistDetailsExist(psychologist);

            if (psychologist) {
                setUserType("userPsychologist");
                dispatch({
                    type: 'SET_USER_TYPE',
                    payload: {
                        currentUser: psychologist,
                        userType: "userPsychologist"
                    }
                });
                requestPermission(psychologist.id, true, true);
                // dispatch({ type: "LOGIN", payload: userAuth });
                setIsLoadingGoogle(false);
                scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                toast.success('Login Successful. You will now automatically get redirected to the Home page.', { containerId: 'therapistRegister' });
                showIntroDetailPage ? setTimeout(() => { navigate('/therapists/dashboard'); }, 3000) : setTimeout(() => { navigate('/therapists/intro'); }, 3000);
            } else {
                setIsLoadingGoogle(false);
                toast.error('Error in login. Please try again later!!', { containerId: 'therapistRegister' });
            }
        } catch (error) {
            setIsLoadingGoogle(false);
            toast.error(error.message, { containerId: 'therapistRegister' });
        }
    };

    const generateCustomUID = (userType) => {
        const prefix = userType === 'therapist' ? 'thp_' : 'usr_';
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let uniqueId = '';
        for (let i = 0; i < 15; i++) {
            uniqueId += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return `${prefix}${uniqueId}`;
    };

    //TODO: Implement Apple Sign In
    const handleAppleClick = async () => {
        try {
            setIsLoadingApple(true);
            const data = await signInWithPopup(auth, appleProvider);
            const user = data.user;
            const credential = OAuthProvider.credentialFromResult(data);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            console.log(accessToken, idToken);
            const psychologistData = {
                id: credential.uid,
                name: user.displayName,
                email: user.email,
                phone: user.phoneNumber ? user.phoneNumber : '',
                countryCode: countryCode,
                professional: selectedProfession === 'true' ? true : false,
            };
            modifyPsychologist(psychologistData);

            await dbHandler.addPsychologist(psychologist);
            setIsLoadingApple(false);

            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            toast.success('Login Successful. You will now automatically get redirected to the Home page', { containerId: 'therapistRegister' });

            setTimeout(() => {
                navigate('/home');
            }, 3300);
        } catch (error) {
            setIsLoadingApple(false);
            toast.error(error.message, { containerId: 'therapistRegister' });
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            // Step 1: Create user with email and password
            const credentials = await auth.createUserWithEmailAndPassword(emailAddress, password);

            // Step 2: Send email verification
            await credentials.user.sendEmailVerification();

            // Step 3: Generate custom UID
            const customUID = generateCustomUID('therapist');

            // Step 4: Store the custom UID and Firebase UID mapping
            await dbHandler.storeUIDReference(credentials.user.uid, customUID);

            // Step 5: Prepare psychologist data with the custom UID
            const psychologistData = {
                id: customUID,
                uid: credentials.user.uid,
                name: fullName,
                email: emailAddress,
                phone: contactNumber,
                countryCode: countryCode,
                professional: selectedProfession === 'true' ? true : false,
            };

            // Step 6: Modify psychologist state
            modifyPsychologist(psychologistData);

            // Step 7: Add psychologist data to Firestore using the custom UID
            await dbHandler.addPsychologist(psychologist);

            // Step 8: Reset form fields and loading state
            setIsLoading(false);
            setFullName("");
            setEmail("");
            setContactNumber("");
            setCountryCode("91");
            setPassword("");
            setSelectedProfession("");

            // Step 9: Show success message and navigate to login page
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            toast.success("Signup Successful. Please check your email for verification. You will be redirected to Login page!!", { containerId: 'therapistRegister' });

            setTimeout(() => {
                navigate("/login-professional");
            }, 3000);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message, { containerId: 'therapistRegister' });
        }
    };

    useEffect(() => {
    }, [psychologist]);

    return (
        <>
            <div ref={scrollRef}></div>
            <HomeNavbar />
            <ToastContainer
                containerId="therapistRegister"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
            <section className="login-back p-3 p-md-4 p-xl-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xxl-11">
                            <div className="card border-light-subtle shadow-sm">
                                <div className="row g-0">
                                    <div className="col-12 col-md-6 d-flex ">
                                        <img className="img-fluid rounded-start w-100" loading="lazy" src={therapistRegLogo} alt="Sign Up and Be Part of Our Community!" />
                                    </div>
                                    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                        <div className="col-12 col-lg-11 col-xl-10">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mb-4">
                                                            <div className="text-center mb-4">
                                                                <Link to="/" aria-current="page" className='logo-link'>
                                                                    <img src={logoImage} alt="BootstrapBrain Logo" width="175" height="100" />
                                                                </Link>
                                                            </div>
                                                            <h4 className="text-center">Sign Up and Be Part of Our Community!</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="other-auth row mt-2">
                                                    <div className="col-12 d-flex flex-column align-items-center">
                                                        <div style={{ width: '300px' }}>
                                                            <div className="other-auth-btn d-flex justify-content-center gap-3">
                                                                <GoogleButton
                                                                    style={{ width: '100%' }}
                                                                    onClick={handleGoogleClick}
                                                                    label={
                                                                        isLoadingGoogle ? (
                                                                            <span>
                                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                            </span>
                                                                        ) : (
                                                                            'Continue with Google'
                                                                        )
                                                                    }
                                                                    disabled={isLoadingGoogle}
                                                                />
                                                            </div>
                                                            <div className="d-flex justify-content-center mt-3" onClick={handleAppleClick}>
                                                                {isLoadingApple ? (
                                                                    <button className="other-auth-btn btn btn-lg btn-dark w-100" type="button" disabled>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    </button>
                                                                ) : (
                                                                    <div className="other-auth-btn btn btn-lg btn-dark w-100 d-flex justify-content-center gap-3">
                                                                        <i className="bi bi-apple me-auto"></i>
                                                                        <span className="mx-auto apple-text">Continue with Apple</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <Link
                                                            to="/register"
                                                            aria-current="page"
                                                            className="therapist-reg-text"
                                                        >
                                                            <h6 className="text-center mt-4 mb-3">Register as an User</h6>
                                                        </Link>
                                                        <p className="text-center  mb-4">Or register with</p>
                                                    </div>
                                                </div>
                                                <form action="#!">
                                                    <Box className="row gy-3 overflow-hidden">
                                                        <Box className="col-12">
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Full Name"
                                                                name="name"
                                                                id="name"
                                                                placeholder="Full Name"
                                                                required
                                                                onChange={(e) => setFullName(e.target.value)}
                                                                value={fullName}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <i className="bi bi-person-fill"></i>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                margin="normal"
                                                            />
                                                        </Box>
                                                        <Box className="col-12">
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Email"
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                placeholder="Email"
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                value={emailAddress}
                                                                autoComplete="username"
                                                                required
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <i className="bi bi-envelope-fill"></i>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                margin="normal"
                                                            />
                                                        </Box>
                                                        <Box className="col-12">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={3}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        label="Code"
                                                                        name="countryCode"
                                                                        id="countryCode"
                                                                        placeholder="91"
                                                                        onChange={(e) => setCountryCode(e.target.value)}
                                                                        value={countryCode}
                                                                        required
                                                                        inputProps={{
                                                                            maxLength: 5,
                                                                        }}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <i className="bi bi-flag-fill"></i>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        margin="normal"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={9}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        label="Contact Number"
                                                                        name="contact"
                                                                        id="contact"
                                                                        placeholder="Contact Number"
                                                                        required
                                                                        value={contactNumber}
                                                                        onChange={handleContactChange}
                                                                        error={!!contactError}
                                                                        helperText={contactError}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <i className="bi bi-telephone-fill"></i>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        margin="normal"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                        <Box className="col-12">
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                label="Password"
                                                                type="password"
                                                                name="password"
                                                                id="password"
                                                                placeholder="Password"
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                value={password}
                                                                required
                                                                autoComplete="current-password"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <i className="bi bi-lock-fill"></i>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                margin="normal"
                                                            />
                                                        </Box>
                                                        <Box className="col-12">
                                                            <FormControl variant="outlined" fullWidth margin="normal">
                                                                <InputLabel id="profession-label">Select Profession</InputLabel>
                                                                <Select
                                                                    labelId="profession-label"
                                                                    id="profession"
                                                                    name="Profession"
                                                                    required
                                                                    onChange={(e) => setSelectedProfession(e.target.value)}
                                                                    value={selectedProfession}
                                                                    input={
                                                                        <OutlinedInput
                                                                            startAdornment={
                                                                                <InputAdornment position="start">
                                                                                    <i className="bi bi-check-square-fill"></i>
                                                                                </InputAdornment>
                                                                            }
                                                                            label="Select Profession"
                                                                        />
                                                                    }
                                                                >
                                                                    <MenuItem value="" disabled>Select Profession</MenuItem>
                                                                    <MenuItem value="true">I am a Professional</MenuItem>
                                                                    <MenuItem value="false">Register as a Peer</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                        <div className="form-btn col-12">
                                                            <div className="d-grid">
                                                                {isLoading ? (
                                                                    <button className="btn btn-dark btn-lg" type="button" disabled>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    </button>
                                                                ) : (
                                                                    <button className="btn btn-dark btn-lg" type="button" onClick={handleLogin}>
                                                                        Sign up now
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Box>
                                                </form>
                                                <div className="addn-info row">
                                                    <div className="col-12">
                                                        <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center mt-5">
                                                            <Link
                                                                to="/login"
                                                                aria-current="page"
                                                                className="addn-info-text"
                                                            > Already a Member? Login  </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
