class User {
    constructor({
        id = '',
        uid = '',
        imagePath = '',
        name = '',
        email = '',
        phone = '',
        countryCode = '',
        referCode = '',
        activeBookingMap = {},
        creditHistory = [],
        activityLevel = '',
        alcoholConsumption = '',
        allergies = '',
        chronicDiseases = '',
        gender = '',
        injuries = '',
        maritalStatus = '',
        occupation = '',
        pastMedication = '',
        currentMedication = '',
        smokingHabits = '',
        surgeries = '',
        creditEarned = 0,
        creditRedeemed = 0,
        referralUserId = '',
        isReferralTrue = 0,
        bookmarkedPost = [],
        config = {},
        deviceToken = [],
    }) {
        this.id = id;
        this.uid = uid;
        this.imagePath = imagePath;
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.countryCode = countryCode;
        this.referCode = referCode;
        this.activeBookingMap = activeBookingMap;
        this.creditHistory = creditHistory;
        this.activityLevel = activityLevel;
        this.alcoholConsumption = alcoholConsumption;
        this.allergies = allergies;
        this.chronicDiseases = chronicDiseases;
        this.gender = gender;
        this.injuries = injuries;
        this.maritalStatus = maritalStatus;
        this.occupation = occupation;
        this.pastMedication = pastMedication;
        this.currentMedication = currentMedication;
        this.smokingHabits = smokingHabits;
        this.surgeries = surgeries;
        this.creditEarned = creditEarned;
        this.creditRedeemed = creditRedeemed;
        this.referralUserId = referralUserId;
        this.isReferralTrue = isReferralTrue;
        this.bookmarkedPost = bookmarkedPost;
        this.config = config;
        this.deviceToken = deviceToken;
    }

    async modifyUser(user) {
        Object.assign(this, user);
    }

    static fromSnapshot(snapshot) {
        const userData = snapshot.data();
        return new User({
            id: snapshot.id,
            ...userData
        });
    }

    isPsy() {
        return !(this.email.isEmpty || this.email.endsWith('@user.com'));
    }

    isUser() {
        return this.email.endsWith('@user.com');
    }
}

export default User;
