import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import { db } from "./Config/Config";
import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import Home from "./Pages/Home/Home";
import ContactUs from "./Pages/Contact/ContactUs";
import ScrollTop from "./Components/ScrollTop";
import Login from "./Pages/Login/Login";
import UserRegister from "./Pages/Login/UserRegister";
import TherapistRegister from "./Pages/Login/TherapistRegister";
import IntroDetails from "./Pages/Therapist/IntroDetails/IntroDetails";
import TherapistHome from "./Pages/Therapist/Home/TherapistHome";
import Settlement from "./Pages/Therapist/SettlementHistory/Settlement";
import TherapistsProfile from "./Pages/Therapist/Profile/TherapistsProfile";
import { AuthContext } from "./Context/AuthContext";
import Error from "./Components/Error";
import "./App.css";
import ShowDeleteAccount from "./Pages/ShowDeleteAccount";
import ConsultationInfo from "./Components/ConsultationInfo/ConsultationInfo";
import ConsultationCard from "./Pages/Therapist/BookingPage/ConsultationCard";
import LiveInstantCall from "./Pages/Therapist/LiveInstantCallPage/LiveInstantCall";
import Faq from "./Components/Faq/Faq";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import UserTerms from "./Components/TermsAndConditions/UserTerms";
import TherapistsTerms from "./Components/TermsAndConditions/TherapistsTerms";
import PeersTerms from "./Components/TermsAndConditions/PeersTerms";
import TherapistPrivacyPolicy from "./Pages/Therapist/PolicyPages/PrivacyPolicy";
import TherapistTermsCond from "./Pages/Therapist/PolicyPages/TherapistTermsCond";
import PeerTermsCond from "./Pages/Therapist/PolicyPages/PeerTermsCond";
import FooterComp from "./Components/footer/footerComp";
import ScrollTopPage from './Components/ScrollTopPage';
import DownloadApp from "./Components/DownloadApp/DownloadApp";
import Notification from "./Components/firebaseNotification/notification";
import DBHandler from "./Utils/DBHandler";
import AppointmentCalendar from "./Components/AppointmentCalendar/AppointmentCalendar";
import UserHome from "./Pages/User/Home/UserHome";
import UserProfile from "./Pages/User/Profile/UserProfile";
import BookingHistory from "./Pages/User/ConsultHistory/BookingHistory";
import LiveInstantCallHistory from "./Pages/User/ConsultHistory/LiveInstantCallHistory";
import FaqComp from "./Components/LoggedFaq/FaqComp";
import UserPrivacyPolicy from "./Pages/User/PolicyPages/PrivacyPolicy";
import UserTermsCond from "./Pages/User/PolicyPages/UserTermsCond";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import TherapistsPage from "./Components/TherapistsPage/TherapistsPage";
import ReferAndEarn from "./Pages/User/ReferAndEarn/ReferAndEarn";
import CreditHistory from "./Pages/User/CreditHistory/CreditHistory";
import BookingConfirmation from "./Components/BookingConfirmation/BookingConfirmation";
import TherapistList from "./Pages/User/TherapistListPage/TherapistList";
import PeerList from "./Pages/User/TherapistListPage/PeerList";
import TherapistHomeList from "./Pages/Home/TherapistHomeList";
import PeerHomeList from "./Pages/Home/PeerHomeList";
import ConsultationDetails from "./Components/ConsultationDetails/ConsultationDetails";

const AppContent = () => {
  const { currentUser, userType } = useContext(AuthContext);
  const [incomingCall, setIncomingCall] = useState(false);
  const [callData, setCallData] = useState(null);
  const dbHandler = useMemo(() => new DBHandler(), []);
  const location = useLocation();

  const onCallRequestPaymentSuccess = useCallback(async (psyId, data, meetLink) => {
    try {
      const callData = {
        psyId,
        userId: data.userId,
        paymentId: data.paymentId,
        amt: data.amt,
        callType: 'Live Call',
      };

      await Promise.all([
        dbHandler.updateCallRequest({ id: psyId, status: "In Call" }),
        dbHandler.updatePsychologist({ id: psyId, status: "In Call" }),
        dbHandler.initiateBookingStatus(currentUser.name, callData, 1)
      ]);

      // Redirect to Google Meet after all updates are done
      window.location.href = `https://meet.google.com/${meetLink}`;
    } catch (error) {
      console.error("Error in onCallRequestPaymentSuccess: ", error);
    } finally {
      setIncomingCall(false);
    }
  }, [dbHandler, currentUser]);



  const onCallRequestPaymentFail = useCallback(async (psyId) => {
    try {
      await Promise.all([
        dbHandler.updateCallRequest({ id: psyId, status: "Available" }),
        dbHandler.updatePsychologist({ id: psyId, status: "Online" })
      ]);
    } catch (error) {
      console.error("Error in onCallRequestPaymentFail: ", error);
    } finally {
      setIncomingCall(false);
      setCallData(null);
    }
  }, [dbHandler]);

  const onCallRequestCancelled = useCallback(async (psyId) => {
    try {
      await Promise.all([
        dbHandler.updateCallRequest({ id: psyId, status: "Available" }),
        dbHandler.updatePsychologist({ id: psyId, status: "Online" }),
        dbHandler.initiateBookingStatus(currentUser.name, callData, -1)
      ]);
    } catch (error) {
      console.error("Error in onCallRequestCancelled: ", error);
    } finally {
      setIncomingCall(false);
      setCallData(null);
    }
  }, [dbHandler]);

  useEffect(() => {
    if (currentUser?.professional) {
      const streamSubscription = db.collection('callRequest')
        .doc(currentUser.id)
        .onSnapshot((doc) => {
          if (!doc.exists) return;
          const data = doc.data();
          const status = data?.status;

          if (!status) return;

          switch (status) {
            case 'Pending':
              setIncomingCall(true);
              setCallData(data);
              break;
            case 'Payment Success':
              onCallRequestPaymentSuccess(currentUser.id, data, currentUser.meetLink);
              break;
            case 'Payment Fail':
              onCallRequestPaymentFail(currentUser.id);
              break;
            case 'Cancelled':
              onCallRequestCancelled(currentUser.id);
              break;
          }
        });

      return () => streamSubscription();
    }
  }, [currentUser, onCallRequestPaymentSuccess, onCallRequestPaymentFail, onCallRequestCancelled]);


  function requirePsychologistUser(userType, allowedTypes, component) {
    if (allowedTypes.includes(userType)) {
      return component;
    } else {
      return <Navigate to="/error" />;
    }
  }

  const TermsComponent = currentUser && currentUser.professional ? <TherapistTermsCond /> : <PeerTermsCond />;

  function requireGeneralUser(userType, component) {
    if (userType === 'generalUser') {
      return component;
    } else {
      return <Navigate to="/error" />;
    }
  }

  return (
    <>
      <ScrollTop />
      <Notification />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/contact" element={<ContactUs />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/therapist" element={<TherapistHomeList />} />
        <Route exact path="/peer" element={<PeerHomeList />} />
        <Route exact path="/error" element={<Error />} />
        <Route exact path="/register" element={<UserRegister />} />
        <Route exact path="/register-professional" element={<TherapistRegister />} />
        <Route exact path="/therapists/resetPassword" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], <ResetPassword currentUser={currentUser} userType={'therapist'} />)} />
        <Route exact path="/delete-account" element={<ShowDeleteAccount />} />
        <Route exact path="/therapists/settlement" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], <Settlement />)} />
        <Route exact path="/therapist/consultationInfo/:userId" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], <ConsultationInfo />)} />
        <Route exact path="/therapists/intro" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], <IntroDetails />)} />
        <Route exact path="/therapists/dashboard" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], <TherapistHome incomingCall={incomingCall} callData={callData} setIncomingCall={setIncomingCall} psyId={currentUser?.id} />)} />
        <Route exact path="/therapists/profile" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], <TherapistsProfile />)} />
        <Route exact path="/therapists/appointments" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], <ConsultationCard />)} />
        <Route exact path="/therapists/bookslots" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], <AppointmentCalendar />)} />
        <Route exact path="/therapists/liveCalls" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], <LiveInstantCall />)} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route exact path="/termsandconditions" element={<UserTerms />} />
        <Route exact path="/therapistTerms" element={<TherapistsTerms />} />
        <Route exact path="/peerTerms" element={<PeersTerms />} />
        <Route exact path="/therapists/faq" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], <FaqComp userType={'therapist'} currentUser={currentUser} />)} />
        <Route exact path="/therapists/privacyPolicy" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], <TherapistPrivacyPolicy />)} />
        <Route exact path="/therapists/terms&Conditions" element={requirePsychologistUser(currentUser && currentUser.professional, [true, false], TermsComponent)} />
        <Route exact path="/downloadapp" element={<DownloadApp />} />
        <Route exact path="/user/dashboard" element={requireGeneralUser(userType, <UserHome />)} />
        <Route exact path="/user/profile" element={requireGeneralUser(userType, < UserProfile />)} />
        <Route exact path="/user/appointments" element={requireGeneralUser(userType, < BookingHistory />)} />
        <Route exact path="/user/liveCalls" element={requireGeneralUser(userType, < LiveInstantCallHistory />)} />
        <Route exact path="/user/faq" element={requireGeneralUser(userType, <FaqComp userType={'user'} currentUser={currentUser} />)} />
        <Route exact path="/user/resetPassword" element={requireGeneralUser(userType, <ResetPassword currentUser={currentUser} userType={'user'} />)} />
        <Route exact path="/user/privacyPolicy" element={requireGeneralUser(userType, < UserPrivacyPolicy />)} />
        <Route exact path="/user/terms&Conditions" element={requireGeneralUser(userType, < UserTermsCond />)} />
        <Route path="/user/therapist/:id" element={requireGeneralUser(userType, <TherapistsPage />)} />
        <Route path="/user/peer/:id" element={requireGeneralUser(userType, <TherapistsPage />)} />
        <Route exact path="/user/creditHistory" element={requireGeneralUser(userType, < CreditHistory />)} />
        <Route exact path="/user/refer&Earn" element={requireGeneralUser(userType, < ReferAndEarn />)} />
        <Route exact path="/user/bookingConfirmation" element={requireGeneralUser(userType, <BookingConfirmation />)} />
        <Route exact path="/user/therapist" element={requireGeneralUser(userType, <TherapistList />)} />
        <Route exact path="/user/peer" element={requireGeneralUser(userType, <PeerList />)} />
        <Route exact path="/user/consultationInfo/:psyId" element={requireGeneralUser(userType, <ConsultationDetails />)} />

      </Routes>
      {!location.pathname.startsWith("/therapists") &&
        !location.pathname.startsWith("/user") &&
        <FooterComp />}
      <ScrollTopPage />
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
