import React, { Suspense, lazy } from 'react'
import image1 from '../../Assets/1.png'
import image2 from '../../Assets/2.png'
import image3 from '../../Assets/3.png'
import './Home.css';
import { Link } from 'react-router-dom';
import HomeNavbar from '../../Components/Navbar';
import GradientCircularProgress from '../../Components/Loader/Loader';
import FaqHome from './FaqHome';
import DownloadApp from '../../Components/DownloadApp/DownloadApp';
import PsychologistCard from './PsyCard';

export default function Home() {
    const LazyBackgroundImage = lazy(() => import('./LazyBackgroundImg'));
    const LazyImage = lazy(() => import('../../Components/LazyImage'));
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <HomeNavbar />
            <Suspense fallback={<GradientCircularProgress />}>
                <LazyBackgroundImage />
            </Suspense>
            <main id="main">
                <section id="about" className="about">
                    <div className="container section-title" data-aos="fade-up">
                        <h1>About Us</h1>
                        <p>Learn more about our mission, values, and commitment to providing top-quality services.</p>
                    </div>
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="row align-items-xl-center gy-5">
                            <div className="col-xl-5 content">
                                <h1>Empowering Mental Wellness</h1>
                                <p>Enabling accessible and affordable mental health services while nurturing connections among users within a supportive community of therapists and peers. Join our initiative to contribute to this endeavor.</p>
                                <Link aria-current="page" className="read-more" to="/" onClick={handleScrollToTop}>
                                    <span>Read More</span><i className="bi bi-arrow-right"></i>
                                </Link>
                            </div>

                            <div className="col-xl-7">
                                <div className="row gy-4 icon-boxes">
                                    <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                                        <div className="icon-box">
                                            <i className="bi bi-command"></i>
                                            <h3>Online Therapy</h3>
                                            <p>Access therapy conveniently from anywhere with internet connectivity</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6" data-aos="fade-up" data-aos-delay="300">
                                        <div className="icon-box">
                                            <i className="bi bi-currency-rupee"></i>
                                            <h3>Affordable Pricing</h3>
                                            <p>Benefit from therapy sessions at the most competitive rates</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
                                        <div className="icon-box">
                                            <i className="bi bi-clipboard-heart-fill"></i>
                                            <h3>Instant Therapist Connection</h3>
                                            <p>Connect promptly with a qualified therapist as soon as you need support</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6" data-aos="fade-up" data-aos-delay="500">
                                        <div className="icon-box">
                                            <i className="bi bi-person-fill-lock"></i>
                                            <h3>Anonymous Therapist</h3>
                                            <p>Experience therapy without the fear of judgment by engaging with our anonymous therapists</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <PsychologistCard isPsy={true} />
                <PsychologistCard isPsy={false} />

                <section id="services" className="services">
                    <div className="container section-title" data-aos="fade-up">
                        <h1>Services</h1>
                        <p>Discover personalized therapy sessions and supportive community engagement to guide you on your journey towards healing and growth.</p>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon-box">
                                    <div className="icon"><i className="bi bi-chat-square-quote-fill"></i></div>
                                    <h4>Self-Care Hub</h4>
                                    <p>Discover inspiration through insightful blogs and find solidarity within our supportive community</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon-box">
                                    <div className="icon"><i className="bi bi-person-lines-fill"></i></div>
                                    <h4>Expert Therapy Sessions</h4>
                                    <p>Take the first step towards healing with personalized therapy sessions tailored to your needs</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon-box">
                                    <div className="icon"><i className="bi bi-people-fill"></i></div>
                                    <h4>Peer Support Network</h4>
                                    <p>Connect instantly with understanding peers and find strength in shared experiences</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="gallery" className="gallery section-bg">
                    <div className="container section-title" data-aos="fade-up">
                        <h1>Gallery</h1>
                        <p>Explore our gallery showcasing the essence of mental healthcare, therapy, and counseling. Immerse yourself in a visual journey of healing, support, and empowerment.</p>
                    </div>
                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                                <div className="gallery-member">
                                    <div className="member-img">
                                        <Suspense fallback={<GradientCircularProgress />}>
                                            <LazyImage src={image1} alt="Gallery Image 1" />
                                        </Suspense>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                                <div className="gallery-member">
                                    <div className="member-img">
                                        <Suspense fallback={<GradientCircularProgress />}>
                                            <LazyImage src={image2} alt="Gallery Image 1" />
                                        </Suspense>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
                                <div className="gallery-member">
                                    <div className="member-img">
                                        <Suspense fallback={<GradientCircularProgress />}>
                                            <LazyImage src={image3} alt="Gallery Image 1" />
                                        </Suspense>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FaqHome />
                <DownloadApp />
            </main>
        </>
    )
}
