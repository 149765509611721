import React, { useEffect, useCallback, useRef, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import HomeNavbar from '../../Components/Navbar';
import { usePsyList } from '../../Utils/Psychologist/PsyListContext';
import TherapistProfileCard from '../../Components/TherapistProfile/TherapistProfileCard';
import Loader from '../../Components/Loader/Loader';
import './Home.css';

export default function TherapistHomeList() {
    const { therapist, loadingTherapist, loadMoreTherapists } = usePsyList();
    const [loadingMore, setLoadingMore] = useState(false);
    const isFetching = useRef(false);

    const fetchTherapists = useCallback(async () => {
        if (isFetching.current || loadingTherapist) return;
        isFetching.current = true;
        setLoadingMore(true);

        try {
            await loadMoreTherapists();
        } catch (error) {
            toast.error('Error fetching more therapists. Please try again later!', { containerId: 'therapistHomeListPage' });
        } finally {
            setLoadingMore(false);
            isFetching.current = false;
        }
    }, [loadingTherapist, loadMoreTherapists]);

    useEffect(() => {
        const handleScroll = () => {
            const nearBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 100;
            if (nearBottom && !loadingMore) {
                fetchTherapists();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [fetchTherapists, loadingMore]);

    return (
        <>
            <ToastContainer
                containerId="therapistHomeListPage"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <HomeNavbar />
            <div className='therapistHomeList'>
                <div className='therapistHomeListContainer'>
                    {loadingTherapist && <Loader />}
                    <TherapistProfileCard psychologist={therapist} psyType={true} isHome={true} />
                    {loadingMore && <Loader />}
                </div>
            </div>
        </>
    );
}
