import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import { AuthContext } from "../../../Context/AuthContext";
import Sidebar from '../../../Components/sidebar/Sidebar';
import Navbar from '../../../Components/navbar/navbar';
import { usePsyList } from '../../../Utils/Psychologist/PsyListContext';
import Loader from '../../../Components/Loader/Loader';
import './style.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TherapistProfileCard from '../../../Components/TherapistProfile/TherapistProfileCard';

export default function TherapistList() {
    const { currentUser } = useContext(AuthContext);
    const { therapist, loadingTherapist, loadMoreTherapists } = usePsyList();
    const [loadingMore, setLoadingMore] = useState(false);
    const isFetching = useRef(false);

    const fetchTherapists = useCallback(async () => {
        if (isFetching.current || loadingTherapist) return;
        isFetching.current = true;
        setLoadingMore(true);

        try {
            await loadMoreTherapists();
        } catch (error) {
            toast.error('Error fetching more therapists. Please try again later!', { containerId: 'therapistHomeListPage' });
        } finally {
            setLoadingMore(false);
            isFetching.current = false;
        }
    }, [loadingTherapist, loadMoreTherapists]);

    useEffect(() => {
        const handleScroll = () => {
            const nearBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 100;
            if (nearBottom && !loadingMore) {
                fetchTherapists();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [fetchTherapists, loadingMore]);

    return (
        <>
            <ToastContainer
                containerId="therapistListPage"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='consultant_home'>
                <Sidebar userType={'user'} />
                <div className='Consultant_home_container'>
                    <Navbar userData={currentUser} userType={'user'} />
                    {loadingTherapist && <Loader />}
                    <h2 className='consultant_call_heading'>Our Therapists</h2>
                    <hr className="divider" />
                    <TherapistProfileCard psychologist={therapist} psyType={true} isHome={false} />
                    {loadingMore && <Loader />}
                </div>
            </div>
        </>
    );
}
