import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FaStar, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { usePsyList } from '../../Utils/Psychologist/PsyListContext';
import Loader from '../../Components/Loader/Loader';
import "./Home.css";

const TherapistCard = React.memo(({ therapist, handleBtnClick, cardHeight }) => (
    <div className="therapist-card" style={{ height: cardHeight }}>
        <div className="user_card_flex">
            <img
                src={therapist.imagePath}
                alt={therapist.name}
                className="therapist-image"
            />
            <div className="user_details_cont">
                <h2 className="therapist-name">Dr. {therapist.name}</h2>
                <p className="therapist-username">{therapist.areas.join(', ')}</p>
                <p className="therapist-details">{therapist.languages.join(', ')}</p>
            </div>
        </div>
        <p className="therapist-bio">{therapist.about}</p>
        <div className="therapist-stats">
            <div>
                <strong>{therapist.rating}</strong>
                <FaStar color="gold" />
            </div>
            <div>
                <strong className="therap_price">₹{therapist.price}</strong>
                <span>/hr</span>
            </div>
        </div>
        {/* <button className="view-profile-btn" onClick={() => handleBtnClick(therapist)}>View profile</button> */}
        <button className="view-profile-btn" onClick={() => { }}>View profile</button>
    </div>
));

const PsyCardRow = ({ psychologists }) => {
    const [startIndex, setStartIndex] = useState(0);
    const [cardHeight, setCardHeight] = useState('auto');
    const navigate = useNavigate();

    const handleBtnClick = (psychologist) => {
        navigate(`/user/therapist/${psychologist.id}`, { state: { psychologist } });
    };

    useEffect(() => {
        const updateCardHeight = () => {
            const cardElements = document.querySelectorAll(".therapist-card");
            if (cardElements.length) {
                const heights = Array.from(cardElements).map(card => card.scrollHeight);
                setCardHeight(`${Math.max(...heights)}px`);
            }
        };

        updateCardHeight();
        window.addEventListener('resize', updateCardHeight);
        return () => window.removeEventListener('resize', updateCardHeight);
    }, [psychologists]);

    const displayedPsychologists = useMemo(() => {
        const endIndex = startIndex + 4;
        if (endIndex <= psychologists.length) {
            return psychologists.slice(startIndex, endIndex);
        } else {
            const wrappedTherapists = [...psychologists.slice(startIndex), ...psychologists.slice(0, endIndex - psychologists.length)];
            return wrappedTherapists;
        }
    }, [startIndex, psychologists]);

    const handlePrev = useCallback(() => {
        setStartIndex((prevIndex) => (prevIndex - 1 + psychologists.length) % psychologists.length);
    }, [psychologists.length]);

    const handleNext = useCallback(() => {
        setStartIndex((prevIndex) => (prevIndex + 1) % psychologists.length);
    }, [psychologists.length]);

    return (
        <div className="therapist-card-container">
            <button className="nav-btn prev" onClick={handlePrev}>
                <FaChevronLeft />
            </button>
            <div className="therapist-card-row">
                {displayedPsychologists.map((psychologist) => (
                    <TherapistCard
                        key={psychologist.id}
                        therapist={psychologist}
                        handleBtnClick={handleBtnClick}
                        cardHeight={cardHeight}
                    />
                ))}
            </div>
            <button className="nav-btn next" onClick={handleNext}>
                <FaChevronRight />
            </button>
        </div>
    );
};

export default function PsychologistCard({ isPsy }) {
    // const [psyData, setPsyData] = useState([]);
    // const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const handleViewBtnClick = useCallback(() => {
        navigate(isPsy ? '/therapist' : '/peer');
    }, [isPsy, navigate]);

    const { therapist, peer, loadingTherapist, loadingPeer } = usePsyList();

    return (
        <div className="home-psychologist-cards">
            <div className="container section-title" data-aos="fade-up">
                <h1>{isPsy ? 'Our Therapists' : 'Our Peer Support'}</h1>
                <p>{isPsy ? 'Connect with licensed professionals dedicated to your mental well-being' : 'Join empathetic peers offering real-life support through shared experiences'}</p>
            </div>

            {isPsy ? (
                loadingTherapist ? (
                    <Loader />
                ) : (
                    <PsyCardRow psychologists={therapist} />
                )
            ) : (
                loadingPeer ? (
                    <Loader />
                ) : (
                    <PsyCardRow psychologists={peer} />
                )
            )}

            <div className="view_cont">
                <button className="view-btn" onClick={handleViewBtnClick}>
                    <span>View More</span><i className="bi bi-arrow-right"></i>
                </button>
            </div>
        </div>
    );
};