import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Button } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import MicIcon from '@mui/icons-material/Mic';
import ReplayIcon from '@mui/icons-material/Replay';
import { useNavigate } from 'react-router-dom';
import './UserAppointmentCard.css';
import GradientCircularProgress from '../../../Components/Loader/GradientCircularProgress';

export const AppointmentCard = ({ initials, consultationData, psychologistData, onClick }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingImg, setIsLoadingImg] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        if (psychologistData?.imagePath) {
            const img = new Image();
            img.src = psychologistData.imagePath;
            img.onload = () => setIsLoadingImg(false);
            img.onerror = () => setIsLoadingImg(false);
        } else {
            setIsLoadingImg(false);
        }
    }, [psychologistData?.imagePath]);

    const dateTimeSlot = useMemo(() => {
        if (consultationData.callType === 'Live Call') {
            return consultationData.timestamp instanceof Date ? consultationData.timestamp : consultationData.timestamp.toDate();
        } else {
            return consultationData.dateTimeSlot instanceof Date ? consultationData.dateTimeSlot : consultationData.dateTimeSlot.toDate();
        }
    }, [consultationData]);

    const dateFormatter = useMemo(() => {
        const dateOptions = { day: 'numeric', month: 'short', year: 'numeric', timeZone: 'Asia/Kolkata' };
        return new Intl.DateTimeFormat('en-IN', dateOptions);
    }, []);

    const timeFormatter = useMemo(() => {
        const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'Asia/Kolkata' };
        return new Intl.DateTimeFormat('en-IN', timeOptions);
    }, []);

    const formattedDate = useMemo(() => dateFormatter.format(dateTimeSlot), [dateTimeSlot, dateFormatter]);
    const formattedTime = useMemo(() => timeFormatter.format(dateTimeSlot), [dateTimeSlot, timeFormatter]);

    const IconComponent = consultationData.sessionMode === 'Video' ? VideocamIcon : MicIcon;

    const handleCardClick = (event) => {
        event.stopPropagation(); // Prevent dialog clicks from triggering card click
        onClick();
    };

    const handleBookAgain = (psychologist) => {
        const route = psychologist.professional ? `/user/therapist/${psychologist.id}` : `/user/peer/${psychologist.id}`;
        navigate(route, { state: { psychologist } });
    }

    return (
        <Box className="Consultantcard">
            <Box className="circle">
                {isLoading ? (
                    <GradientCircularProgress />
                ) : (
                    <>
                        {isLoadingImg ? (
                            <GradientCircularProgress />
                        ) : (
                            psychologistData?.imagePath ? (
                                <img
                                    src={psychologistData.imagePath}
                                    alt={psychologistData.name}
                                    className="profile-pic"
                                />
                            ) : (
                                <img
                                    src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=800"
                                    alt="Default Profile"
                                    className="profile-pic"
                                />
                            )
                        )}
                    </>
                )}
            </Box>
            <Box className="card-content">
                <div className='name_bg' onClick={handleCardClick}>
                    <Typography className="Consultantname">
                        {psychologistData?.professional
                            ? `Dr. ${psychologistData?.name}`
                            : psychologistData?.name}
                    </Typography>
                    <Typography className="Consultantphone">
                        {psychologistData?.professional ? 'Therapist' : 'Counselor'}
                    </Typography>
                    <Box className="areas-languages-row">
                        <Typography className="Consultantphone">
                            {psychologistData?.areas?.join(', ')}
                        </Typography>
                        <Typography className="Consultantphone">
                            {psychologistData?.languages?.join(', ')}
                        </Typography>
                    </Box>
                </div>

                <Box className="details" onClick={handleCardClick}>
                    <IconComponent className="Videoicon" />
                    <Box className="detail-item">
                        <Typography className="detail-text">
                            {consultationData.sessionMode} Consultation
                        </Typography>
                        <Box className="detail-info">
                            <Typography className="detail-info">@ {formattedDate}</Typography>
                            <Typography className="detail-info">{formattedTime}</Typography>
                        </Box>
                    </Box>
                    <Box className="detail-item">
                        <Typography className="detail-text">Amount Paid</Typography>
                        <Typography className="detail-info">
                            {`₹${consultationData.amt}`}
                        </Typography>
                    </Box>
                </Box>

                <hr className="divider" />

                {consultationData.status === 'Payment Fail' && (
                    <Box className="payment-failed-text">
                        <Typography className='payment-failed-text'>Payment Failed</Typography>
                    </Box>
                )}

                {consultationData.bookingStatus !== 0 && (
                    <Button
                        className="bookAgain-button"
                        onClick={() => handleBookAgain(psychologistData)}
                    >
                        <ReplayIcon className="bookAgain-icon" />
                        <Typography className="bookAgain-text">Book Again</Typography>
                    </Button>
                )}

                {/* Uncomment this if needed */}
                {/* {consultationData.bookingStatus === 0 && (
                    <Box className="scheduled-text">
                        <Typography className='scheduled-text'>Scheduled</Typography>
                    </Box>
                )} */}
            </Box>
        </Box>
    );
};
