import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PsychologistProvider } from './Utils/Psychologist/PsychologistContext';
import { PsyListProvider } from './Utils/Psychologist/PsyListContext';
import { UserProvider } from './Utils/User/UserContext';
import { AuthContextProvider } from './Context/AuthContext';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PsychologistProvider> {/*When Psy Login for their personal data*/}
      <UserProvider>{/* When General User Login for their personal data*/}
        <AuthContextProvider> {/* For login & authentication*/}
          <PsyListProvider>{/* To dispaly all the listed psy profile throught the entire app*/}
            <App />
          </PsyListProvider>
        </AuthContextProvider>
      </UserProvider>
    </PsychologistProvider>
  </React.StrictMode >
);

reportWebVitals();
serviceWorkerRegistration.register();
