import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { AuthContext } from "../../../Context/AuthContext";
import Sidebar from '../../../Components/sidebar/Sidebar';
import Navbar from '../../../Components/navbar/navbar';
import { usePsyList } from '../../../Utils/Psychologist/PsyListContext';
import Loader from '../../../Components/Loader/Loader';
import './style.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TherapistProfileCard from '../../../Components/TherapistProfile/TherapistProfileCard';

export default function PeerList() {
    const { currentUser } = useContext(AuthContext);
    const { peer, loadingPeer, loadMorePeers } = usePsyList();
    const [loadingMore, setLoadingMore] = useState(false);
    const isFetching = useRef(false);

    const fetchPeers = useCallback(async () => {
        if (isFetching.current || loadingPeer) return;
        isFetching.current = true;
        setLoadingMore(true);

        try {
            await loadMorePeers();
        } catch (error) {
            toast.error('Error fetching more peer. Please try again later!', { containerId: 'peerHomeListPage' });
        } finally {
            setLoadingMore(false);
            isFetching.current = false;
        }
    }, [loadingPeer, loadMorePeers]);

    useEffect(() => {
        const handleScroll = () => {
            const nearBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 100;
            if (nearBottom && !loadingMore) {
                fetchPeers();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [fetchPeers, loadingMore]);

    return (
        <>
            <ToastContainer
                containerId="peerListPage"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='consultant_home'>
                <Sidebar userType={'user'} />
                <div className='Consultant_home_container'>
                    <Navbar userData={currentUser} userType={'user'} />
                    {loadingPeer && <Loader />}
                    <h2 className='consultant_call_heading'>Our Peers</h2>
                    <hr className="divider" />
                    <TherapistProfileCard psychologist={peer} psyType={false} isHome={false} />
                    {loadingMore && <Loader />}
                </div>
            </div>
        </>
    );
}
